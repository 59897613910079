/***********************************************/
/* activate the animation using FLIP technique */

[data-enter="invert"]{
    transform: translate(calc(var(--dx) * 1px), calc(var(--dy) * 1px))
      scale(var(--dw), var(--dh));
}

[data-enter="play"]{
    transform: none;
}



[data-leave="invert"]{
    transform: translate(calc(var(--dx) * 1px), calc(var(--dy) * 1px))
      scale(var(--dw), var(--dh));
}

[data-leave="play"]{
    transform: none;
}

/*******************/

.container[data-state="expanded"] *{
    transition-property: transform opacity;
    transition-duration: var(--enterance-duration, 1.5s);
}

.container[data-state="login"] *{
    transition-property: transform opacity;
    transition-duration: var(--leave-duration, 1.5s);
}

/*******************/

.container{
  background: black;
  position: relative;
  width: 100vw;
  height: 100vh;

  overflow: hidden;

  background-image: url("/assets/map-overlay.svg");
  background-repeat: no-repeat;
  background-size: cover;


  --enterance-duration: 1s;
  --enterance-timing-function: cubic-bezier(0.31, 0.79, 0.47, 0.9); 

  --leave-duration: 1s;
  --leave-timing-function: cubic-bezier(0.07, 1.04, 0.73, 1.03);


  transition-property: all;
  transition-duration: var(--leave-duration);
  transition-timing-function: var(--enterance-timing-function);
}

.container[data-state="login"]{
  background-image: none;
}

/******************/

.layer1{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
}

.inviteOnly{
  letter-spacing: 4px;
}

.container[data-state="login"] .inviteOnly,
.container[data-state="expanded"] .inviteOnly{
  transform: translateX(-40vw);
}

@media screen and (max-width: 768px){
  .layer1{
    justify-content: flex-start;
    padding-left: 40px;
  }
  .container[data-state="login"] .inviteOnly,
  .container[data-state="expanded"] .inviteOnly{
    transform: translateY(-25vh);
  }
}

/*************/

.layer2{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
}

.whiteJet{
  max-width: 50vw;
  transition-timing-function: var(--enterance-timing-function) !important;
  transition-delay: 0.1s;
  max-height: 50vh;
}

.container[data-state="collapsed"] .whiteJet{
  transform: translateX(100vw);
}


.container[data-state="login"] .whiteJet{
  transform: translateX(-100vw);
}

@media screen and (max-width: 768px){

  .whiteJet{
    max-width: 60vw;
  }

  .jetContainer{
    transform: rotate(90deg);
  }

  .container[data-state="collapsed"] .whiteJet{
    transform: translateX(100vh);
  }


  .container[data-state="login"] .whiteJet{
    transform: translateX(-100vh);
  }
}

/******************/

.layer3{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 1;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5vw;
}

.global{
  /* need-fix, material-ui styles are overriding normal css rules */
  font-size: 2.5rem !important; 
  font-weight: 600 !important;

  position: relative;
  transition-delay: calc(var(--enterance-duration) / 2);
  transition-timing-function: var(--enterance-timing-function);
}

.global sup{
  font-size: 0.5rem;
  position: absolute;
  top: 8px;
  right: -10px;
}

.container[data-state="collapsed"] .global, 
.container[data-state="login"] .global
{
  opacity: 0;
}

.container[data-state="expanded"] .global{
  opacity: 1;
}


@media screen and (max-width: 768px){
  .layer3{
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20vh;
  }
}
/******************/


.layer4{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 4;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0 0 5vw 5vw;
}

.arrowContainer{
  position: relative;
}

.line{
  height: 2px;
  width: 50vw;
  background: var(--primary-color);
}

.arrow {
  width: 10px;
  height: 10px;
  left: calc(100% - 10px);
  bottom: -4px;
  margin: 0;
  position: absolute;
  transform:rotate(45deg);
  border-right: 2px solid var(--primary-color);
  border-top: 2px solid var(--primary-color);
}

.container[data-state="expanded"] .layer4{
  transform: translateX(-25vw);
}
.container[data-state="login"] .layer4{
  transform: translateX(-100vw);
}

.scrollText1, .scrollText2{
  color: #d1d1d1;
  text-transform: uppercase;
  display: inline;
  white-space: nowrap;
  padding-bottom: 10px;
}

@media screen and (max-width: 768px){
  .layer4{
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 25vh;
  }

  .arrowContainer{
    transform: rotate(90deg);
  }

  .scrollText2, .scrollText1{
    display: none;
  }

  .container[data-state="login"] .layer4,
  .container[data-state="expanded"] .layer4{
    transform: translateY(100vh);
  }
}

/******************/

.layer5{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 5; /*7 in beta, when beta ends it should be 5 again*/

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 0 5.5vw 5vw;

}

.scrollText2{
  transition-property: opacity;
  transition-delay: calc(var(--enterance-duration) / 2);
  transition-timing-function: var(--enterance-timing-function);
  transition-duration: var(--enterance-duration);

  opacity: 0;
}

.applyNowBtn{
  transition-property: opacity;
  transition-delay: calc(var(--enterance-duration) / 2);
  transition-timing-function: var(--enterance-timing-function);
  transition-duration: var(--enterance-duration);
  position: relative;
  top: 1px;
  margin-left: 20px !important;
  opacity: 0;
  font-weight: 600;
  color: white !important;
  font-size: 1.2rem !important;
}

.container[data-state="login"] .applyNowBtn,
.container[data-state="login"] .scrollText2{
  opacity: 1;
}

/******************/

.layer6{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 6;

  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  padding-top: 8vh;
}

.layer6ArrowContainer{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layer6Content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 100px
}
.layer6ActionContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.layer6ActionContainer .line{
  width: 20vw;
}
.container[data-state="login"] .layer6{
  opacity: 1;
  animation-name: login-form-enter-desktop;
  animation-duration: calc(var(--leave-duration) * 1.3);
  animation-timing-function: var(--leave-timing-function);
}

@media screen and (max-width: 768px){
  .container[data-state="login"] .layer6{
    animation-name: login-form-enter-mobile;
  }
}

/******************/

.whiteColor{
  color: white;
}

/******************/

@keyframes login-form-enter-mobile{
  from {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to{
    clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
    opacity: 1;
  }
}
@keyframes login-form-enter-desktop{
  from{
    clip-path: polygon(100% 0%, 100% 0, 100% 100%, 100% 100%);
  }
  to{
    clip-path: polygon(100% 0%, 0 0, 0 100%, 100% 100%);
    opacity: 1;
  }
}


.resetPassword {
    width: 100%;
    height: 100vh;
    background-color: black;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 110px;
}

.resetPasswordContainer {
    display: flex;
    flex-direction: row;
    gap: 68px;
    margin-bottom: 20px !important;
}

.resetPasswordHeadingTypo {
    color: #FFF !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    letter-spacing: 4px !important;
    margin-top: 15px !important;
}

.resetInputContainer {
    padding: 6px 32px;
    border-radius: 5px;
    max-width: 660px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

.resetDivider {
    height: 20px !important;
    margin: 0px 20px !important;
    background: #2C62E0 !important;
    display: block !important;
}

.resetDividerErrorTypo {
    width: 100%;
    text-align: right;
    padding-right: 285px !important;
}

.forgotResendTypo {
    color: var(--primary-color);
    cursor: pointer;
    font-size: 20px !important;
}

.forgotPasswordResend {
    font-size: 20px !important;
    color: white !important;
    margin-top: 20px !important
}

.forgotPasswordResendMobile {
    display: none !important;
}

@media only screen and (max-width: 960px){

    .resetPassword {
        padding: 0 40px;
        justify-content: flex-start;
    }

    .resetPasswordContainer {
        flex-direction: column;
        align-items: flex-start;
    } 

    .resetPasswordHeadingTypo {
        font-size: 15px !important;
        margin-top: 140px !important;
    }

    .resetInputContainer {
        flex-direction: column;
        border: 0 !important;
        margin-right: 0;
        justify-content: flex-start;
        gap: 20px;
        padding: 20px 0px;
        width: 100%;
    }

    .resetDivider {
        display: none !important;
    }

    .resetErrorTypo {
        padding-right: 0px !important;
    }

    .resetDividerErrorTypo {
        width: 100%;
        text-align: left;
        font-size: 12px !important;
        padding-right: 0px !important;
    }

    .forgotPasswordResend{
        display: none !important;
    }

    .forgotPasswordResendMobile{
        display: block !important;
        font-size: 12px !important;
        color: white !important;
    }
  }
.loginFormInputsContainer{
    display: grid;
    grid-template-columns: 38% 7% 38% 7% 10%; 
    justify-content: space-around;
    align-items: center;

    padding: 5px 20px;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    margin: 0px;
    min-width: 55vw;
}

.loginFormInputsContainer button{
  font-weight: 600;
}

.input{
  position: relative;
  top: 2px;
}

.input input{
  color: white;
  font-weight: 600;
}

.input input::placeholder{
  color: white;
  font-weight: 600;
  opacity: 0.6;
}


.loginFormInputsContainer hr{
  height: 20px;
  margin: 0 20px;
  background: var(--primary-color);
}

.alertsContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;

}

.alertsContainer>*{
  margin-right: 10px;

  animation-delay: 0.4s;
  animation-name: shake-animation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

.rememberMeContainer{
  display: grid;
  grid-template-areas: "checkbox forgot-password api-error";
  grid-template-columns: repeat(3,33.33%);
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}
.rememberMeCheckbox{
  width: fit-content;
  grid-area: checkbox;
}
.forgotPasswodText2{
  width: fit-content;
  grid-area: forgot-password;
}
.apiErrorMsg{
  grid-area: api-error;
  text-align: right;
}

.rememberMeContainer *{
  color: white;
}


.errorText2{
  text-decoration: none;
  color: var(--error-color);
}

.errorText2 p{
  color: inherit;
}

.loginBtnMobile{
  display: none !important;
}

@media screen and (max-width: 768px){

  .rememberMeContainer{
    display: grid;
    grid-template-areas: "checkbox forgot-password" "api-error api-error";
    grid-template-columns: repeat(2,50%);
    grid-template-rows: 1fr 1fr;
    justify-content: space-between;
  }


  .rememberMeCheckbox span{
    font-size: 12px;
  }

  .forgotPasswodText2{
    width: fit-content;
    text-align: right;
  }

  .errorText2 p{
    font-size: 12px;
  }

  .form{
    width: 80vw;
    max-width: 80vw;
  }

  .loginBtnMobile{
    display: block !important;
  }

  .loginBtnDesktop{
    display: none !important;
  }



  .loginFormInputsContainer{
    grid-template-columns: 100%;
    border: none;
    padding: 0;
    min-width: max-content;
  }

  .loginFormInputsContainer > button{
    align-self: flex-end;
    width: max-content;
  }

  .loginFormInputsContainer hr{
    height: 0;
    width: 0;
    margin: 10px 0;
  }

  .alertsContainer{
   flex-direction: column;
  }
  .alertsContainer>*{
    width: 100%;
    margin: 5px 0;
  }
}

